import { Checkbox, Input } from 'mondrian-react'
import { ChangeEvent, FormEvent, useState } from 'react'

import { CustomInput, Drawer } from '../../shared'

import { makeQueryString } from '../../utils'

import { useSessionStorage, useTagging } from '../../hooks'
import { getViability } from './lead-service'
import styles from "./styles.module.scss"

interface FormDrawer {
  open: boolean
  mountedQueryString: string
  handleClose: () => void
}

type TechnologiesType = 'gpon' | 'hfc' | 'dth'

const validate = {
  cep: (cep: string) => cep.length < 8,
  number: (number: string) => number.length === 0
}

const FormDrawer: React.FC<FormDrawer> = ({ open, mountedQueryString, handleClose }) => {
  const { handleTagging: handleDrawerTagging } = useTagging('plano-claro-res:cobertura:modal-acesso-rede-movel-claro:drawer')
  const [isLoading, setIsLoading] = useState(false)
  const { getItem: getAffiliateIdItem } = useSessionStorage('affiliateId')
  const [state, setState] = useState({
    checked: false,
    cep: '',
    number: ''
  })


  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    if (state.cep.length < 8) return

    try {
      setIsLoading(true)
      const { technologies } = await getViability(state.cep, state.number)
      const isGpon = !!technologies.find(tech => tech.gpon && tech.name === 'Cable')
      const isHFC = !!technologies.find(tech => !tech.gpon && tech.name === 'Cable')
      const currTechnology: TechnologiesType = isGpon ? 'gpon' : isHFC ? 'hfc' : 'dth'
      const affiliateId = getAffiliateIdItem()
      const prospectUrl = process.env.prospectUrl
      const userData = {
        cep: state.cep,
        number: state.number,
        affiliateId
      }
      const mountCep = makeQueryString(userData)
      const URLroute = currTechnology === 'dth' ? '/monte-sua-combinacao' : `/checkout/dados-pessoais`
      const URLquery = mountedQueryString ? `&${mountedQueryString}` : ''
      const URLredirect = `${prospectUrl}${URLroute}?${mountCep}${URLquery}`
      handleDrawerTagging({
        eventAction: "clique:botao",
        eventLabel: "consultar-disponibilidade"
      })
      window.location.href = URLredirect
    } catch (error) {
      console.error('Error on redirect', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target

    if (ev.target.value.startsWith('0') && name === 'number') return


    const tagmentByCRO = {
      cep: () => handleDrawerTagging({
        eventAction: "interacao:campo",
        eventLabel: "cep"
      }),
      number: () => handleDrawerTagging({
        eventAction: "interacao:campo",
        eventLabel: "numero"
      }),
    }

    tagmentByCRO[name]()
    setState({ ...state, [name]: value.replace(/[^0-9]/g, '') })
  }

  const disabledButton = isLoading || validate.cep(state.cep) || validate.number(state.number)

  return (
    <Drawer
      open={open}
      onClick={handleClose}
      customStyles={{
        overflowY: 'hidden',
        maxWidth: '400px'
      }}
    >
      <div className={styles.EcomFormDrawerContainer}>
        <span onClick={handleClose} />
        <div>
          <p>Para prosseguir, consulte a cobertura.</p>
          <p>Coloque o CEP e o número do local de instalação.</p>
        </div>
        <form className={styles.EcomFormDrawerForm} onSubmit={handleSubmit} id='form'>
          <div>
            <div>
              <Input
                id='cep'
                name='cep'
                cep
                onChange={handleInputChange}
                value={state.cep}
              >
                CEP
              </Input>
            </div>
            <div>
              <CustomInput
                id="number"
                name="number"
                type="number"
                value={state.number}
                disabled={state.checked}
                label="Número*"
                onChange={handleInputChange}
              />
            </div>
            <div>
              <Checkbox
                checked={state.checked}
                onChange={() => {
                  setState({ ...state,
                    checked: !state.checked,
                    number: !state.checked ? '0' :''
                  })
                  handleDrawerTagging({
                    eventAction: 'selecionar:checkbox',
                    eventLabel: `${state.checked ? 'desmarcou:sem-numero' : 'marcou:sem-numero'}`
                  })
                }}
                parentId={'i1'}
                id='withoutNumber'
                name='withoutNumber'
              >
                Meu endereço não possui número.
              </Checkbox>
            </div>
          </div>
          <button disabled={disabledButton}>
            {isLoading ? 'Consultando disponibilidade...' : 'Consultar disponibilidade'}
          </button>
        </form>
      </div >
    </Drawer >
  )
}

export default FormDrawer
