import axios, { AxiosResponse } from "axios";
import { MobileInfoResponse } from "../types/IdentificationApi/MobileResponse";
import { MobileTokenResponse } from "../types/IdentificationApi/MobileTokenResponse";
import { mockedData } from "../mocks/MobileInfo";

class MobileInfoService {
  private readonly SMS_ECARE_API_URL = "https://api-prd.clarobrasil.mobi/sms-ecare/";
  private readonly MOBILE_INFO_API_URL = "https://api-prd.clarobrasil.mobi/identification/customer/mobile/info";

  constructor() { return }

  /**
   * Sends an SMS to the provided MSISDN (phone number).
   * @param msisdn - The MSISDN (phone number) to send the SMS to.
   * @returns A promise that resolves to an AxiosResponse object containing
   * the token received from the sms-ecare API.
   */
  async sendSMS(msisdn: string): Promise<AxiosResponse<MobileTokenResponse>> {
    const url = `${this.SMS_ECARE_API_URL}${msisdn}`;
    return axios.post(url, { msisdn });
  }

  /**
   * Queries customer information based on the provided CPF, MSISDN, code, and token.
   * @param cpf - The CPF of the customer to be queried.
   * @param msisdn - The MSISDN (phone number) of the customer to be queried.
   * @param code - The code received via SMS.
   * @param token - The token used to validate the code. Obtained from the sms-ecare API.
   * @returns A promise that resolves to an AxiosResponse object containing the customer information.
   */
  async consultar(cpf: string, msisdn: string, code: string, token: string): Promise<AxiosResponse<MobileInfoResponse>> {
    // Verificar se o CPF existe no mock antes de fazer a chamada à API
    if (cpf in mockedData) {
      const mockedResponse = mockedData[cpf];
      if (mockedResponse.type === 'error') {
        return Promise.reject({
          response: { data: mockedResponse.response }
        });
      }

      if (mockedResponse.type === 'success') {
        const axiosResponse: AxiosResponse<MobileInfoResponse> = {
          data: mockedResponse.response,
          status: 200,
          statusText: 'OK',
          headers: {},
          config: {}
        };

        return Promise.resolve(axiosResponse);
      }
    }

    const params = { cpf, msisdn, code, token };
    return axios.get<MobileInfoResponse>(this.MOBILE_INFO_API_URL, { params });
  }
}

export default new MobileInfoService();
